<template>
  <div class="dropdown" :class="{ 'dropdown-selected': selected }">
    <transition name="fade">
      <p v-if="selected" class="dropdown-label">{{ label }}</p>
    </transition>
    <div
      :name="name"
      class="dropdown-top click"
      :class="{ 'dropdown-open': open, 'dropdown-error': error }"
    >
      <p v-if="!selected">{{ label }}</p>
      <input v-else :name="name" :value="selected" readonly />
      <ChevronDownIcon />
    </div>
    <div class="dropdown-content" v-if="open">
      <slot />
    </div>
  </div>
</template>

<script>
import { ChevronDownIcon } from "@heroicons/vue/outline";

export default {
  props: {
    name: String,
    selected: String,
    error: Boolean,
    open: Boolean,
    label: String,
  },
  components: { ChevronDownIcon },
};
</script>

<style>
.dropdown {
  flex-direction: column;
  position: relative;
  display: flex;
  gap: 4px;
}

.dropdown-label {
  font-size: var(--font-small);
  color: var(--grey);
  margin-left: 18px;
}

.dropdown-top {
  border-radius: var(--border-radius);
  justify-content: space-between;
  border: 2px solid transparent;
  height: var(--size-textfield);
  padding: 3px 18px 0 18px;
  background: var(--bg);
  align-items: center;
  position: relative;
  color: var(--primary);
  display: flex;
}

.dropdown-top input {
  all: unset;
}

:not(.dropdown-selected) > .dropdown-error:not(.dropdown-open).dropdown-top {
  border: 2px solid var(--red);
  transition: var(--animation);
  animation: var(--shake);
  color: var(--red);
}

.dropdown-top:not(.dropdown-open):hover {
  border: 2px solid var(--accent) !important;
  color: var(--accent) !important;
}

.dropdown-open.dropdown-top {
  background: var(--primary);
  color: white;
}

.dropdown-selected .dropdown-top {
  border: 2px solid var(--primary);
  background: white;
  color: var(--primary);
}

.dropdown-top svg {
  transition: var(--animation);
  height: var(--size-icon);
  width: var(--size-icon);
  color: var(--grey);
}

.dropdown-open.dropdown-top svg {
  transform: rotate(180deg);
}

.dropdown-content > div {
  top: calc(var(--size-textfield) + 10px);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  flex-direction: column;
  background: white;
  position: absolute;
  padding: 10px;
  display: flex;
  z-index: 10;
  width: 100%;
  gap: 10px;
}

.dropdown-selected .dropdown-content > div {
  top: calc(var(--size-textfield) + 30px);
}

.dropdown-content > div > div {
  border-radius: var(--border-radius);
  height: var(--size-textfield);
  align-items: center;
  padding-left: 8px;
  padding-top: 3px;
  cursor: pointer;
  display: flex;
}

.dropdown-content > div > div:hover {
  background: var(--accent);
  color: white;
}

.dropdown-content p {
  align-items: center;
  display: flex;
  height: 100%;
  width: 100%;
}

.dropdown-content span {
  background: var(--accent);
  margin: -3px 10px 0 0;
  border-radius: 10px;
  height: 3px;
  width: 10px;
}
</style>
