<template>
  <div
    class="input click"
    :class="{
      'input-error': error,
      'input-empty': value == null || !value.length,
    }"
  >
    <transition name="fade" class="input-top">
      <label v-if="value != null && value.length" :for="propid">
        {{ checkOptional(label) }}
        <p v-if="optional">(optional)</p>
      </label>
    </transition>
    <textarea
      v-if="paragraph"
      :name="name"
      :id="propid"
      :placeholder="label"
      :value="value"
      v-bind="$attrs"
      @change="$emit('update:modelValue', $event.target.value)"
    />
    <input
      v-else
      :name="name"
      :id="propid"
      :type="type"
      :placeholder="label"
      :value="value"
      v-bind="$attrs"
      @change="$emit('update:modelValue', $event.target.value)"
    />
  </div>
</template>

<script>
export default {
  props: {
    type: { type: String, default: "text" },
    label: { type: String, required: true },
    propid: { type: String, required: true },
    name: { type: String, required: true },
    paragraph: { type: Boolean },
    optional: { type: Boolean },
    value: { required: true },
    error: { type: Boolean },
  },
  methods: {
    checkOptional(a) {
      if (this.optional) {
        return a.replace("(optional)", "");
      } else return a;
    },
  },
};
</script>

<style scoped>
.input {
  transition: var(--animation);
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  gap: 4px;
}

.input-top,
.input-top label {
  align-items: center;
  display: flex;
  gap: 4px;
}

.input-top p {
  font-size: var(--font-small);
  color: var(--accent);
  font-style: italic;
}

.input label {
  font-size: var(--font-small);
  color: var(--accent);
  margin-left: 15px;
}

.input-empty label {
  color: var(--accent);
}

.input input,
.input textarea {
  border-radius: var(--border-radius);
  min-height: var(--size-textfield);
  border: 2px solid transparent;
  padding: 3px 18px 0 18px;
  white-space: nowrap;
  overflow: scroll;
  cursor: pointer;
  width: 100%;
  font: unset;
}

.input textarea {
  white-space: normal;
  min-height: 100px;
  max-height: 400px;
  resize: vertical;
  overflow: auto;
  padding: 18px;
  height: 200px;
}

.input-empty input,
.input-empty textarea {
  background: var(--bg);
}

.input input::placeholder,
.input textarea::placeholder {
  color: var(--primary);
}

.input input:hover::placeholder,
.input textarea:hover::placeholder {
  color: var(--accent);
}

.input input:not(:placeholder-shown),
.input textarea:not(:placeholder-shown) {
  border: 2px solid var(--primary);
  color: var(--primary);
}

.input input:focus,
.input textarea:focus {
  border: 2px solid var(--accent);
  outline: none;
  cursor: unset;
}

.input input:hover,
.input textarea:hover {
  border: 2px solid var(--accent);
  background: none;
}

.input-error input,
.input-error textarea {
  border: 2px solid var(--red);
  transition: var(--animation);
  animation: var(--shake);
  color: var(--red);
}

.input-error input::placeholder,
.input-error textarea::placeholder {
  color: var(--red);
}
</style>
