<template>
  <Page id="contact" :class="mq">
    <section
      class="section-indent section-top page-top"
      aria-labelledby="aria-ourteam"
    >
      <h1 id="aria-ourteam">Contact Us</h1>
      <div style="display: flex; flex-direction: column; gap: 10px">
        <p>
          Please feel free to contact our friendly team using any of the methods
          below.
        </p>
        <i style="font-size: var(--font-small)">
          <b>NOTE:</b> Please do not send any confidential information via our
          form or email.
        </i>
      </div>
      <ContactMap />
    </section>

    <section id="contact-row" class="section-indent">
      <FormContact />
      <div id="contact-right">
        <h2>Contact Methods</h2>
        <div>
          <div class="contact-method">
            <p><b>Happy Dental</b></p>
            <p>123 Random Street</p>
            <p>Romsey SO51 ZZ00</p>
          </div>
          <div class="contact-method">
            <p>
              Call:
              <b><a href="TEL:02382444386">02382444386</a></b>
            </p>
            <p>
              Email:
              <b>
                <a href="MAILTO:hello@viscreo.co.uk"> hello@viscreo.co.uk </a>
              </b>
            </p>
          </div>
        </div>
      </div>
    </section>
  </Page>
</template>

<script>
import ContactMap from "@/components/pages/contact/ContactMap.vue";
import FormContact from "@/components/forms/FormContact.vue";
import Page from "@/components/core/Page.vue";

export default {
  inject: ["mq"],
  components: { FormContact, ContactMap, Page },
};
</script>

<style scoped>
#contact-row {
  grid-template-columns: 1fr 1fr;
  display: grid;
  width: 100%;
  gap: 60px;
}

.brTablet #contact-row,
.brMobile #contact-row {
  grid-template-columns: 1fr;
  gap: 80px;
}

#contact-right,
#contact-right > div {
  flex-direction: column;
  text-align: center;
  display: flex;
  height: 100%;
  gap: 60px;
}

#contact-right > h2 {
  text-align: center;
  width: 100%;
}

.contact-method {
  flex-direction: column;
  display: flex;
  gap: 5px;
}
</style>
