<template>
  <div class="formcontact" :class="mq">
    <h2 style="width: 100%; text-align: center; margin-bottom: 30px">
      Get In Touch
    </h2>
    <form class="formcontact">
      <div class="formcontact-row">
        <Input
          propid="formcontact-fname"
          :error="fnameError"
          label="First Name"
          v-model="fname"
          :value="fname"
          name="fname"
          required
        />
        <Input
          propid="formcontact-lname"
          :error="lnameError"
          label="Last Name"
          v-model="lname"
          :value="lname"
          name="lname"
          required
        />
      </div>
      <Input
        propid="formcontact-email"
        :error="emailError"
        label="Email"
        v-model="email"
        :value="email"
        name="emial"
        required
      />
      <Input
        propid="formcontact-phone"
        :error="phoneError"
        label="Phone"
        v-model="phone"
        :value="phone"
        name="Phone"
        required
      />

      <Dropdown
        @click="isOpen = !isOpen"
        :selected="dropdown"
        name="patient-type"
        :open="isOpen"
        label="Reason"
      >
        <div @click.stop>
          <div v-for="o in options" :key="o" @click="select(o)">
            <p><span /> {{ o }}</p>
          </div>
        </div>
      </Dropdown>

      <Input
        propid="formcontact-message"
        :error="messageError"
        label="Message"
        name="message"
        :value="message"
        v-model="message"
        :paragraph="true"
        required
      />
    </form>
    <Button value="Submit" :disabled="!valid" @click="navAbout" :full="true" />
  </div>
</template>

<script>
import Dropdown from "@/components/global/Dropdown.vue";
import Button from "@/components/global/Button.vue";
import Input from "@/components/global/Input.vue";
import mixins from "@/javascript/mixins.js";

export default {
  inject: ["mq"],
  mixins: [mixins],
  components: { Dropdown, Button, Input },
  data() {
    return {
      /* Dropdown Control */
      isOpen: false,
      options: [
        "Patient Examination",
        "Hygienist Visit",
        "Routine Visit",
        "Emergency",
      ],

      /* Data Capture */
      dropdown: null,
      message: null,
      fname: null,
      lname: null,
      email: null,
      phone: null,
    };
  },
  methods: {
    select(a) {
      this.isOpen = false;
      this.dropdown = a;
    },
    navAbout() {
      this.$router.push("/");
    },
  },
  computed: {
    fnameError() {
      return this.fname == "" ? true : false;
    },
    lnameError() {
      return this.lname == "" ? true : false;
    },
    emailError() {
      return this.email == "" ? true : false;
    },
    phoneError() {
      return this.phone == "" ? true : false;
    },
    messageError() {
      return this.message == "" ? true : false;
    },
    valid() {
      if (
        this.dropdown == null ||
        this.message == null ||
        this.fname == null ||
        this.lname == null ||
        this.email == null ||
        this.phone == null ||
        this.messageError ||
        this.fnameError ||
        this.lnameError ||
        this.emailError ||
        this.phoneError
      )
        return false;
      else return true;
    },
  },
};
</script>

<style scoped>
.formcontact {
  justify-content: center;
  flex-direction: column;
  align-items: stretch;
  display: flex;
  gap: 15px;
}

.brTablet.formcontact,
.brMobile.formcontact,
.brTablet .formcontact,
.brMobile .formcontact {
  border-radius: unset;
  min-width: unset;
  width: 100%;
}

.formcontact-row {
  justify-content: space-between;
  align-items: flex-end;
  display: flex;
  width: 100%;
  gap: 15px;
}

.brMobile .formcontact-row,
.brTablet .formcontact-row {
  flex-direction: column;
  align-items: strech;
}

.formcontact-row .input {
  flex: 1;
}

.formcontact .input {
  min-width: 220px;
}

.brTablet.formcontact .input,
.brMobile.formcontact .input {
  min-width: unset;
  width: 100%;
}
</style>
