<template>
  <section
    @mouseleave="hover = false"
    @mouseover="hover = true"
    class="page-top"
    id="contactmap"
    :class="mq"
  >
    <transition name="fade" mode="in-out">
      <div
        v-if="!hover || isMobile"
        id="contactmap-overlay"
        class="true-center"
      >
        <Button value="Find Us in Romsey" @click="navMap" />
      </div>
    </transition>
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d20088.044295172705!2d-1.5032806355478745!3d50.997569825191974!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48738a467a935321%3A0x921d2f3be7c06e5f!2sRomsey!5e0!3m2!1sen!2suk!4v1658833617897!5m2!1sen!2suk"
      referrerpolicy="no-referrer-when-downgrade"
      style="border: 0"
      allowfullscreen
      frameborder="0"
      loading="lazy"
    />
  </section>
</template>

<script>
import Button from "@/components/global/Button.vue";
import mixins from "@/javascript/mixins.js";

export default {
  inject: ["mq"],
  mixins: [mixins],
  components: { Button },
  data() {
    return {
      hover: false,
    };
  },
  methods: {
    navMap() {
      window.open(
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d20088.044295172705!2d-1.5032806355478745!3d50.997569825191974!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48738a467a935321%3A0x921d2f3be7c06e5f!2sRomsey!5e0!3m2!1sen!2suk!4v1658833617897!5m2!1sen!2suk"
      );
    },
  },
};
</script>

<style scoped>
#contactmap {
  border-radius: var(--border-radius);
  position: relative;
  overflow: hidden;
  cursor: pointer;
  height: 300px;
  width: 100%;
}

#contactmap > iframe,
#contactmap-overlay {
  height: 100%;
  width: 100%;
}

#contactmap-overlay {
  background: var(--primary-transparent);
  position: absolute;
  left: 0;
  top: 0;
}

.brMobile #contactmap-overlay {
  backdrop-filter: blur(6px);
}
</style>
